import * as React from 'react'
import { useAxios } from '../utils/hooks'
import {useState, useEffect} from "react";
import { WindowInterface } from '../interfaces/Interface';

import {FaCheck, FaTimes, FaPlus} from 'react-icons/fa';
import moment from "moment-timezone";
import {MomentInput} from "moment/moment";
import {toast} from "react-toastify";
import {RequestModel} from "../interfaces/RequestModel";
import Modal from "react-modal";
import {useForm} from "react-hook-form";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [requests, setRequests] = useState<RequestModel[]>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [selectingRequest,setSelectingRequest] = useState<RequestModel | undefined>(undefined);

    const [deleteModalIsOpen,setDeleteModalIsOpen] = useState(false);
    function closeDeleteModal(){
        setDeleteModalIsOpen(false);
        setSelectingRequest(undefined);
    }
    function openDeleteModal(report:RequestModel){
        setDeleteModalIsOpen(true);
        setSelectingRequest(report);
        reset();
    }

    const { register, handleSubmit, reset, getValues} = useForm();
    const onSubmit = (data : any) => {
        if(axiosInstance.current && selectingRequest !== undefined) {
            selectingRequest.devComment = data.comment;
            selectingRequest.resolved = (data.resolved==="yes");
            selectingRequest.sendMail = data.sendMail;
            axiosInstance.current?.put('/v1/requests/' + selectingRequest.id, selectingRequest).then(data => {
                if(data.status === 200) {
                    deleteRequest(selectingRequest);
                }else{
                    toast.error("An error as occurred");
                }
            });
        }
    }

    const saveDraft = () => {
        if(axiosInstance.current && selectingRequest !== undefined) {
            selectingRequest.devComment = getValues("comment");
            selectingRequest.resolved = (getValues("resolved")==="yes");
            selectingRequest.sendMail = getValues("sendMail");
            axiosInstance.current?.put('/v1/reports/' + selectingRequest.id, selectingRequest).then(data => {
                if(data.status === 200) {
                    toast.success("Request saved");
                }else{
                    toast.error("An error as occurred");
                }
            });
        }
    }

    function deleteRequest(request: RequestModel | undefined){
        if(request !== undefined) {
            axiosInstance.current && axiosInstance.current.delete('/v1/requests/' + request.id).then(data => {
                if (data.status === 200) {
                    if(requests !== undefined){
                        const newList : Array<RequestModel> = requests.map((item) => {
                            if (item.id === request.id) {
                                item.deletedAt = "test";
                            }

                            return item;
                        });

                        setRequests(newList);
                    }
                    toast.success("Request closed")
                    closeDeleteModal()
                } else {
                    toast.error("An error as occurred");
                }
            });
        }
    }

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/requests').then(data => setRequests(data.data));
            setisLoading(false);
        }
        getList();
    }, []);

  return (
      <div>
          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1"} >
              <div className="col">
                <h1>Requests</h1>
              </div>
          </div>

          {isLoading &&
              <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
              </div>
          }

          {!isLoading && requests && requests.filter(value => value.deletedAt == null).length === 0 &&
          <div className={"no-item"}>
              <FaPlus/><br />
              Awesome, no more requests
          </div>
          }

          {!isLoading && requests &&
              <div className="row gy-3 row-cols-1 list">
                  {requests.filter(value => value.deletedAt == null).map((value, index) => {
                      return (
                          <div className="col" key={index}>
                              <div
                                  className={`row shadow-sm item bg-dark`}>
                                  <div className="col-lg-7 col-md-5 col-sm-5 col-5 my-auto">
                                      <h4>{value.name}</h4>
                                      <span className={"text-muted"} >{value.nameOfManufacturer}</span>
                                  </div>
                                  <span className="col-lg-4 col-md-6 col-sm-6 col-6 my-auto">
                                      {value.email}<br/>
                                      <span className={"text-muted"} >{moment(value.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss") }</span>
                                  </span>
                                  <div className="col-lg-1 col-md-1 col-sm-1 col-1 my-auto">
                                      <button className="btn btn-secondary dropdown-toggle" onClick={() => openDeleteModal(value)} >
                                          <FaCheck/>
                                      </button>
                                  </div>
                              </div>
                          </div>
                      )
                  })}
              </div>
          }

          <Modal
              isOpen={deleteModalIsOpen}
              onRequestClose={closeDeleteModal}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Close request</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeDeleteModal()}><FaTimes /></button>
              </div>
              <div className="row gy-3 row-cols-1">
                  <form onSubmit={handleSubmit(onSubmit)} >
                      <div className="mb-3">
                          <div className="form-check">
                              <input {...register("resolved", { required: true })} defaultChecked={selectingRequest?.resolved} className="form-check-input" type="radio" id="yes" value="yes" />
                              <label className="form-check-label" htmlFor="yes">
                                  Yes i added fixture
                              </label>
                          </div>
                          <div className="form-check">
                              <input {...register("resolved", { required: true })} defaultChecked={!selectingRequest?.resolved} className="form-check-input" type="radio"  id="no" value="no" />
                              <label className="form-check-label" htmlFor="no">
                                  No i can't add the fixture
                              </label>
                          </div>
                          <hr/>
                      </div>
                      <div className="mb-3">
                          <textarea style={{height: "200px"}} className="form-control" id="comment" placeholder={"Developer comment (used for client email) ..."} {...register("comment")} defaultValue={selectingRequest?.devComment} ></textarea>
                      </div>
                      <div className="mb-3 form-check">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" {...register("sendMail")} defaultChecked={selectingRequest?.sendMail}  />
                          <label className="form-check-label" htmlFor="flexCheckChecked">
                              Send an email to client
                          </label>
                      </div>
                      <div className="mb-3">
                          <hr/>
                          <button className={"btn btn-light"} onClick={saveDraft} type={"button"} >Save draft</button>
                          <input className={"btn btn-primary float-right"} type="submit" value={"Close request"} />
                      </div>
                  </form>
              </div>
          </Modal>
      </div>
  )
}
