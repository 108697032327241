import * as React from 'react'
import { NavLink } from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import { WindowInterface } from '../interfaces/Interface';

import {FaAngleRight, FaTimes, FaCheck, FaFileAlt, FaExclamationTriangle} from 'react-icons/fa';
import moment from "moment-timezone";
import {MomentInput} from "moment/moment";
import Modal from "react-modal";
import {ReportModel} from "../interfaces/ReportModel";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [reports, setReports] = useState<ReportModel[]>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [selectingReport,setSelectingReport] = useState<ReportModel | undefined>(undefined);

    const [deleteModalIsOpen,setDeleteModalIsOpen] = useState(false);
    function closeDeleteModal(){
        setDeleteModalIsOpen(false);
        setSelectingReport(undefined);
    }
    function openDeleteModal(report:ReportModel){
        setDeleteModalIsOpen(true);
        setSelectingReport(report);
        reset();
    }

    const [commentModalIsOpen,setCommentModalIsOpen] = useState(false);
    function closeCommentModal(){
        setCommentModalIsOpen(false);
        setSelectingReport(undefined);
    }
    function openCommentModal(report:ReportModel){
        setCommentModalIsOpen(true);
        setSelectingReport(report);
    }

    const { register, handleSubmit, reset, getValues} = useForm();
    const onSubmit = (data : any) => {
        if(axiosInstance.current && selectingReport !== undefined) {
            selectingReport.devComment = data.comment;
            selectingReport.resolved = (data.resolved==="yes");
            selectingReport.sendMail = data.sendMail;
            axiosInstance.current?.put('/v1/reports/' + selectingReport.id, selectingReport).then(data => {
                if(data.status === 200) {
                    deleteReport(selectingReport);
                }else{
                    toast.error("An error as occurred");
                }
            });
        }
    }

    const saveDraft = () => {
        if(axiosInstance.current && selectingReport !== undefined) {
            selectingReport.devComment = getValues("comment");
            selectingReport.resolved = (getValues("resolved")==="yes");
            selectingReport.sendMail = getValues("sendMail");
            axiosInstance.current?.put('/v1/reports/' + selectingReport.id, selectingReport).then(data => {
                if(data.status === 200) {
                    toast.success("Report saved");
                }else{
                    toast.error("An error as occurred");
                }
            });
        }
    }

    function deleteReport(report: ReportModel | undefined){
        if(report !== undefined) {
            axiosInstance.current && axiosInstance.current.delete('/v1/reports/' + report.id).then(data => {
                if (data.status === 200) {
                    if(reports !== undefined){
                        const newList : Array<ReportModel> = reports.map((item) => {
                            if (item.id === report.id) {
                                item.deletedAt = "test";
                            }

                            return item;
                        });

                        setReports(newList);
                    }
                    toast.success("Report closed")
                    closeDeleteModal()
                } else {
                    toast.error("An error as occurred");
                }
            });
        }
    }

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/reports').then(data => setReports(data.data));
            setisLoading(false);
        }
        getList();
    }, []);

  return (
      <div>
          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1"} >
              <div className="col">
                <h1>Reports</h1>
              </div>
          </div>

          {isLoading &&
              <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
              </div>
          }

          {!isLoading && reports && reports.filter(value => value.deletedAt == null).length === 0 &&
              <div className={"no-item"}>
                  <FaExclamationTriangle/><br />
                  Awesome, no more reports
              </div>
          }

          {!isLoading && reports &&
              <div className="row gy-3 row-cols-1 list">
                  {reports.filter(value => value.deletedAt == null).map((value, index) => {
                      return (
                          <div className="col" key={index}>
                              <div
                                  className={`row shadow-sm item bg-dark`}>
                                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 my-auto">
                                      <h4>
                                          <NavLink to={"/fixtures/" + value.fixture.id} >
                                              {value.fixture.name}
                                              <FaAngleRight/>
                                          </NavLink>
                                      </h4>
                                      <span className={"text-muted"} >{value.fixture.manufacturer.name}</span>
                                  </div>
                                  <span className="col-lg-4 col-md-6 col-sm-6 col-6 my-auto">
                                      {value.email}<br/>
                                      <span className={"text-muted"} >{moment(value.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss") }</span>
                                  </span>
                                  <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-auto comment">
                                      <span className={"text-muted"} >
                                          { (value.comment) ? value.comment.slice(0, 10) : "No user comment"}
                                          {(value.comment && value.comment.length > 10) &&
                                              <span>
                                                ... <button className={"more_comment"} onClick={() => openCommentModal(value)}><FaFileAlt/></button>
                                              </span>
                                          }
                                      </span>
                                  </div>
                                  <div className="col-lg-1 col-md-6 col-sm-6 col-6 my-auto">
                                      <button className="btn btn-secondary" onClick={() => openDeleteModal(value)}><FaCheck/></button>
                                  </div>
                              </div>
                          </div>
                      )
                  })}
              </div>
          }

          <Modal
              isOpen={commentModalIsOpen}
              onRequestClose={closeCommentModal}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <div className={"close"}>
                  <button className="btn" onClick={() => closeCommentModal()}><FaTimes /></button>
              </div><br />
              <div className="row gy-3 row-cols-1">
                  {selectingReport?.comment}
              </div>
          </Modal>

          <Modal
              isOpen={deleteModalIsOpen}
              onRequestClose={closeDeleteModal}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Close report</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeDeleteModal()}><FaTimes /></button>
              </div>
              <div className="row gy-3 row-cols-1">
                  <form onSubmit={handleSubmit(onSubmit)} >
                      <div className="mb-3">
                          <div className="form-check">
                              <input {...register("resolved", { required: true })} defaultChecked={selectingReport?.resolved} className="form-check-input" type="radio" id="yes" value="yes" />
                              <label className="form-check-label" htmlFor="yes">
                                  Yes i resolve the problem
                              </label>
                          </div>
                          <div className="form-check">
                              <input {...register("resolved", { required: true })} defaultChecked={!selectingReport?.resolved} className="form-check-input" type="radio"  id="no" value="no" />
                              <label className="form-check-label" htmlFor="no">
                                  No i can't resolve
                              </label>
                          </div>
                          <hr/>
                      </div>
                      <div className="mb-3">
                        <textarea style={{height: "200px"}} className="form-control" id="comment" placeholder={"Developer comment (used for client email) ..."} {...register("comment")} defaultValue={selectingReport?.devComment} ></textarea>
                      </div>
                      <div className="mb-3 form-check">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" {...register("sendMail")} defaultChecked={selectingReport?.sendMail}  />
                              <label className="form-check-label" htmlFor="flexCheckChecked">
                                  Send an email to client
                              </label>
                      </div>
                      <div className="mb-3">
                          <hr/>
                          <button className={"btn btn-light"} onClick={saveDraft} type={"button"} >Save draft</button>
                          <input className={"btn btn-primary float-right"} type="submit" value={"Close report"} />
                      </div>
                  </form>
              </div>
          </Modal>
      </div>
  )
}
