import * as React from 'react'
import { Redirect, useParams } from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import { WindowInterface } from '../interfaces/Interface';
import AsyncSelect from 'react-select/async';

import {SuggestionModel} from "../interfaces/SuggestionModel";
import {ManufacturerModel} from "../interfaces/ManufacturerModel";
import {FixtureTypeModel} from "../interfaces/FixtureTypeModel";
import { useForm, Controller} from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import {FixtureModel} from "../interfaces/FixtureModel";
import {toast} from "react-toastify";

const customStyles = {
    option: (provided:any, state:any) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
    })
}

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [redirect, setRedirect] = useState<string>("");
    const [suggestion, setSuggestion] = useState<SuggestionModel>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [manufacturers, setManufacturers] = useState<ManufacturerModel[]>();
    const [fixtureTypes, setFixtureTypes] = useState<FixtureTypeModel[]>();

    const [foundedManufacturer, setFoundedManufacturer] = useState<ManufacturerModel>();
    const [foundedFixtureType, setFoundedFixtureType] = useState<FixtureTypeModel>();

    const {control, register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data : any) => {
        console.log(data);
        if(suggestion?.properties){
            var newFixture : FixtureModel = {
                name: data.name,
                numberOfChannels: data.numberOfChannels,
                properties: suggestion?.properties,
                fixtureType: {id: data.fixtureType.value, label: "", image: ""},
                manufacturer: {id: data.manufacturer.value, name: "", deletedAt: "", fixtureCount: 0, createdAt: "", updatedAt: ""},
                id : -1,
                reportCount: 0,
                deletedAt: "",
                createdAt: "",
                updatedAt: "",
                manualUrl: ""
            };

            Promise.all([axiosInstance.current?.post('/v1/fixtures/', newFixture), axiosInstance.current && axiosInstance.current.delete('/v1/suggestions/' + suggestion.id)]).then((values) => {
                console.log(values);
                if(values[0] && values[1] && values[0].status === 200 && values[1].status === 200){
                    setRedirect("/fixtures/"+values[0].data.id);
                    toast.success("Fixture created");
                }else{
                    toast.error("An error as occurred");
                }
            });
        }
    }

    interface Params {
        suggestionId: string
    }
    let { suggestionId } = useParams<Params>();

    useEffect(() => {
        const getList = async () => {
            if(suggestionId !== undefined){
                axiosInstance.current && await axiosInstance.current.get('/v1/suggestions/' + suggestionId).then(data => setSuggestion(data.data));
                axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers').then(data => setManufacturers(data.data));
                axiosInstance.current && await axiosInstance.current.get('/v1/fixturetypes').then(data => setFixtureTypes(data.data));
                setisLoading(false);
            }
        }
        getList();
    }, []);

    useEffect(() => {
        if(manufacturers && manufacturers.filter(value => value.name === suggestion?.nameOfManufacturer).length === 1){
            setFoundedManufacturer(manufacturers.filter(value => value.name === suggestion?.nameOfManufacturer)[0]);
        }

        if(fixtureTypes && fixtureTypes.filter(value => value.label === suggestion?.type).length === 1){
            setFoundedFixtureType(fixtureTypes.filter(value => value.label === suggestion?.type)[0]);
        }
    }, [manufacturers, fixtureTypes, suggestion]);

    const getManufacturersOptions = (input:string) => {
        return new Promise<{value:string,label:string}[]>((resolve) => {
            axiosInstance.current && axiosInstance.current.get((input !== "")?'/v1/manufacturers/search/'+input:"/v1/manufacturers/")
                .then(function (response) {
                    resolve(response.data.map((manufacturer: ManufacturerModel) => ({
                        value: manufacturer.id,
                        label: manufacturer.name
                    })));
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    const getFixtureTypesOptions = (input:string) => {
        return new Promise<{value:string,label:string}[]>((resolve) => {
            axiosInstance.current && axiosInstance.current.get('/v1/fixturetypes')
                .then(function (response) {
                    resolve(response.data.map((fixtureType: FixtureTypeModel) => ({
                        value: fixtureType.id,
                        label: fixtureType.label
                    })));
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

  return (
      <div className={"accept_fixture"}>
          {isLoading &&
          <div className="spinner-border loader" role="status">
              <span className="sr-only"></span>
          </div>
          }

          {!isLoading &&
            <div>
                <h1>Create fixture with suggestion</h1>
                <div className={"list"} >
                    <h3>User suggestion</h3>
                    <div className={"row item bg-dark"} >
                        <div className={"col-3"} >
                            Name : <b>{suggestion?.name}</b>
                        </div>
                        <div className={"col-3"} >
                            Number of channels : <b>{suggestion?.numberOfChannels}</b>
                        </div>
                        <div className={"col-3"} >
                            Manufacturer : <b>{suggestion?.nameOfManufacturer}</b>
                        </div>
                        <div className={"col-3"} >
                            Type : <b>{suggestion?.type}</b>
                        </div>
                    </div>
                </div><br />

                <form onSubmit={handleSubmit(onSubmit)}  className={"row g-3"} noValidate={true}>
                    <h3>Complete the request</h3>
                    <div className="col-6">
                        <label htmlFor={"name"} >Name : </label>
                        <input {...register("name", { required: true })} defaultValue={suggestion?.name} type={"text"} name={"name"}
                               className={`form-control ${
                                    errors.name ? "is-invalid" : ""
                                }`}
                        />
                        <ErrorMessage className="invalid-feedback" name="name" as="div" errors={errors} message={"Please give a name !"} />
                    </div>

                    <div className={"col-6"} >
                        <label htmlFor={"numberOfChannels"} >Number of channels : </label>
                        <input {...register("numberOfChannels", { required: true })} defaultValue={suggestion?.numberOfChannels} type={"text"} name={"numberOfChannels"}
                               className={`form-control ${
                                   errors.numberOfChannels ? "is-invalid" : ""
                               }`}
                        />
                        <ErrorMessage className="invalid-feedback" name="numberOfChannels" as="div" errors={errors} message={"Please give the number of channels !"} />
                    </div>

                    <div className="col-6">
                        <label htmlFor={"manufacturer"} >Manufacturer : </label>
                        <Controller
                            defaultValue={(foundedManufacturer)?{label: foundedManufacturer?.name, value: foundedManufacturer?.id}:""}
                            control={control}
                            name="manufacturer"
                            rules={{ required: true }}
                            render={({
                                         field: { onChange, onBlur, value, name, ref },
                                         fieldState: { invalid, isTouched, isDirty, error },
                                     }) => (
                                <AsyncSelect value={value}
                                             onChange={onChange}
                                             inputRef={ref}
                                             id="manufacturer"
                                             name="manufacturer"
                                             styles={customStyles}
                                             isClearable defaultOptions loadOptions={getManufacturersOptions}
                                             className={`${
                                                 errors.manufacturer ? "is-invalid" : ""
                                             }`}
                                />
                            )}
                        />
                        <ErrorMessage className="invalid-feedback" name="manufacturer" as="div" errors={errors} message={"Please select a manufacturer !"} />
                    </div>

                    <div className="col-6">
                        <label htmlFor={"fixtureType"} >Type : </label>
                        <Controller
                            defaultValue={(foundedFixtureType)?{label: foundedFixtureType?.label, value: foundedFixtureType?.id}:""}
                            control={control}
                            name="fixtureType"
                            rules={{ required: true }}
                            render={({
                                         field: { onChange, onBlur, value, name, ref },
                                         fieldState: { invalid, isTouched, isDirty, error },
                                     }) => (
                                <AsyncSelect value={value}
                                             onChange={onChange}
                                             inputRef={ref}
                                             id="fixtureType"
                                             name="fixtureType"
                                             styles={customStyles}
                                             isClearable defaultOptions loadOptions={getFixtureTypesOptions}
                                             className={`${
                                                 errors.fixtureType ? "is-invalid" : ""
                                             }`}
                                />
                            )}
                        />
                        <ErrorMessage className="invalid-feedback" name="fixtureType" as="div" errors={errors} message={"Please select a type !"} />
                    </div>

                    <div className="col-12">
                        <input className={"btn btn-primary"} type="submit" value={"Create fixture"} />
                    </div>
                </form>
            </div>
          }
      </div>
  )
}
