import * as React from 'react'
import { NavLink } from "react-router-dom";
import { useAxios } from '../utils/hooks'
import {useState, useEffect, ChangeEvent} from "react";
import { useForm } from "react-hook-form";
import { WindowInterface } from '../interfaces/Interface';
import Modal from 'react-modal';
import { ManufacturerModel } from '../interfaces/ManufacturerModel';
import {FaAngleRight, FaPlus} from "react-icons/fa";
import {toast} from "react-toastify";
import {useDebounce} from "use-debounce";

const customStyles = {
    content : {
        width                 : '500px',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')


export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [manufacturers, setManufacturers] = useState<Array<ManufacturerModel>>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [text, setText] = useState("");
    const [value] = useDebounce(text, 500);

    useEffect(() => {
        if(!isLoading){
            setisLoading(true);
            const getList = async () => {
                if(value === ""){
                    axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers').then(data => setManufacturers(data.data));
                }else{
                    axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers/search/' + value).then(data => setManufacturers(data.data));
                }
                setisLoading(false);
            }
            getList();
        }
    }, [value]);

    const handleChange = (event: ChangeEvent<{ value: string }>) => {
        setText(event.target.value);
    }

    const { register, handleSubmit, reset} = useForm();
    const onSubmit = (data : any) => {
        var manufacturer:ManufacturerModel = {id: -1, name: data.name, deletedAt: "", fixtureCount: 0, updatedAt: "", createdAt: ""};
        axiosInstance.current?.post('/v1/manufacturers/', manufacturer).then(data => {
            if(data.status === 200) {
                manufacturers?.push(data.data);
                setManufacturers(manufacturers);

                closeModal();
                toast.success("Manufacturer created");
            }else{
                toast.error("An error as occurred");
            }
        });
    }

    const [editModalIsOpen,setEditModalIsOpen] = useState(false);
    function openModal(manufacturer: ManufacturerModel|undefined) {
        reset();
        setEditModalIsOpen(true);
    }
    function closeModal(){
        setEditModalIsOpen(false);
    }

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers').then(data => setManufacturers(data.data));
            setisLoading(false);
        }
        getList();
    }, []);

    if(isLoading)
        return (
            <div className="spinner-border loader" role="status">
                <span className="sr-only"></span>
            </div>
        );

  return (
      <div className={"manufacturers"}>

          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2"} >
              <div className="col">
                  <h1>Manufacturers <button className="btn btn-primary rounded-circle" onClick={() => openModal(undefined)}><FaPlus/></button></h1>
              </div>
              <div className="col">
                  <input type="text" name="name" className={"search"} placeholder={"Search ..."} value={text} onChange={handleChange}/>
              </div>
          </div>

          <div className="row gy-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-3 list">
              {manufacturers!.filter(value => value.deletedAt == null).sort((a, b) => {return (a.name < b.name)?-1:1}).map((value, index) => {
                  return (
                      <NavLink to={"/manufacturers/"+value.id+"/fixtures"} >
                          <div className="col" key={index}>
                              <div className="row shadow-sm bg-dark item">
                                  <div className="col-10 my-auto">
                                      <h4>{value.name}</h4>
                                      <span className={"text-muted"} >{value.fixtureCount} fixtures</span>
                                  </div>
                                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 my-auto">
                                      <FaAngleRight/>
                                  </div>
                              </div>
                          </div>
                      </NavLink>
                  )
              })}
          </div>
          <Modal
              isOpen={editModalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Edit manufacturer"
              className="Modal"
              overlayClassName="Overlay"
          >

              <h2>
                  Create manufacturer
              </h2>
              <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" id="name" placeholder={"Name of manufacturer"} {...register("name", { required: true })} required />
                  </div>
                  <input className={"btn btn-primary"} type="submit" />
              </form>
          </Modal>
      </div>
  )
}
