import * as React from 'react'
import {Redirect, useParams} from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import {WindowInterface} from '../interfaces/Interface';

import {SuggestionModel} from "../interfaces/SuggestionModel";
import AsyncSelect from 'react-select/async';
import {ManufacturerModel} from "../interfaces/ManufacturerModel";
import {FixtureModel} from "../interfaces/FixtureModel";
import { useForm, Controller} from "react-hook-form";
import {FixtureTypeModel} from "../interfaces/FixtureTypeModel";
import {FaArrowDown, FaCloudDownloadAlt, FaTimes} from "react-icons/fa";
import {ErrorMessage} from "@hookform/error-message";
import Modal from "react-modal";
import {toast} from "react-toastify";

const customStyles = {
    option: (provided:any, state:any) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
    })
}

const customModalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [redirect, setRedirect] = useState<string>("");
    const [selectedFixture, setSelectedFixture] = useState<FixtureModel>();
    const [suggestion, setSuggestion] = useState<SuggestionModel>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [manufacturers, setManufacturers] = useState<ManufacturerModel[]>();
    const [fixtureTypes, setFixtureTypes] = useState<FixtureTypeModel[]>();

    const [foundedManufacturer, setFoundedManufacturer] = useState<ManufacturerModel>();
    const [foundedFixtureType, setFoundedFixtureType] = useState<FixtureTypeModel>();

    const {control, register, handleSubmit, formState: { errors }, getValues } = useForm();
    const onSubmit = (data : any) => {
        openConfirmModal();
    }

    const merge = () => {
        if(selectedFixture && suggestion){

            selectedFixture.properties = suggestion.properties;

            if(getValues("mergeName")){
                selectedFixture.name = suggestion.name;
            }

            if(getValues("mergeChannelNumber")){
                selectedFixture.numberOfChannels = suggestion.numberOfChannels;
            }

            if(getValues("mergeManufacturerName") && foundedManufacturer){
                selectedFixture.manufacturer = foundedManufacturer;
            }

            if(getValues("mergeType") && foundedFixtureType){
                selectedFixture.fixtureType = foundedFixtureType;
            }

            Promise.all([axiosInstance.current?.put('/v1/fixtures/'+selectedFixture.id, selectedFixture), axiosInstance.current && axiosInstance.current.delete('/v1/suggestions/' + suggestion.id)]).then((values) => {
                console.log(values);
                if(values[0] && values[1] && values[0].status === 200 && values[1].status === 200){
                    setRedirect("/fixtures/"+values[0].data.id);
                    toast.success("Fixture created");
                }else{
                    toast.error("An error as occurred");
                }
            });
            closeConfirmModal();
        }
    }

    interface Params {
        suggestionId: string
    }
    let { suggestionId } = useParams<Params>();

    useEffect(() => {
        const getList = async () => {
            if(suggestionId !== undefined){
                axiosInstance.current && await axiosInstance.current.get('/v1/suggestions/' + suggestionId).then(data => setSuggestion(data.data));
                axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers').then(data => setManufacturers(data.data));
                axiosInstance.current && await axiosInstance.current.get('/v1/fixturetypes').then(data => setFixtureTypes(data.data));
                setisLoading(false);
            }
        }
        getList();
    }, []);

    useEffect(() => {
        if(manufacturers && manufacturers.filter(value => value.name === suggestion?.nameOfManufacturer).length === 1){
            setFoundedManufacturer(manufacturers.filter(value => value.name === suggestion?.nameOfManufacturer)[0]);
        }

        if(fixtureTypes && fixtureTypes.filter(value => value.label === suggestion?.type).length === 1){
            setFoundedFixtureType(fixtureTypes.filter(value => value.label === suggestion?.type)[0]);
        }
    }, [manufacturers, fixtureTypes, suggestion]);

    const getFixturesOptions = (input:string) => {
        return new Promise<{value:string,label:string}[]>((resolve) => {
            axiosInstance.current && axiosInstance.current.post('/v1/fixtures/search/', {query: input})
                .then(function (response) {
                    resolve(response.data.map((fixture: FixtureModel) => ({
                        value: fixture.id,
                        label: "#"+fixture.id+" " + fixture.name + " ("+fixture.manufacturer.name+")"
                    })));
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    const [confirmModalIsOpen,setConfirmModalIsOpen] = useState(false);
    function openConfirmModal() {
        setConfirmModalIsOpen(true);
    }
    function closeConfirmModal(){
        setConfirmModalIsOpen(false);
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

  return (
      <div className={"merge_fixture"}>
          {isLoading &&
          <div className="spinner-border loader" role="status">
              <span className="sr-only"></span>
          </div>
          }

          {!isLoading &&
            <div>
                <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1"} >
                    <div className="col">
                        <h1>Merge suggestion into fixture</h1>
                        <div className={"list"} >
                            <form onSubmit={handleSubmit(onSubmit)}  className={"row g-3"} noValidate={true}>
                                <h3>User suggestion</h3>
                                <div className={"row item bg-dark"} >
                                    <div className={"col-3"} >
                                        Name : <b>{suggestion?.name}</b>
                                    </div>
                                    <div className={"col-3"} >
                                        Number of channels : <b>{suggestion?.numberOfChannels}</b>
                                    </div>
                                    <div className={"col-3"} >
                                        Manufacturer : <b>{suggestion?.nameOfManufacturer}</b>
                                    </div>
                                    <div className={"col-3"} >
                                        Type : <b>{suggestion?.type}</b>
                                    </div>
                                </div><br />
                                <h3>Select fixture</h3>
                                <div className={"row item bg-dark"} >
                                    <div className={"col-12"} >
                                        <label htmlFor={"fixture"} >Fixture : </label>
                                        <Controller
                                            control={control}
                                            name="manufacturer"
                                            rules={{ required: true }}
                                            render={({
                                                 field: { onChange, onBlur, value, name, ref },
                                                 fieldState: { invalid, isTouched, isDirty, error },
                                             }) => (
                                                <AsyncSelect
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        if(value){
                                                            axiosInstance.current && axiosInstance.current.get('/v1/fixtures/' + value.value).then(dataFixture => {
                                                                setSelectedFixture(dataFixture.data);
                                                            });
                                                        }
                                                        else
                                                            setSelectedFixture(undefined);
                                                    }}
                                                             id="fixture"
                                                             name="fixture"
                                                             styles={customStyles}
                                                             isClearable defaultOptions
                                                             loadOptions={getFixturesOptions}
                                                             className={`${
                                                                 errors.manufacturer ? "is-invalid" : ""
                                                             }`}
                                                />
                                            )}
                                        />
                                        <ErrorMessage className="invalid-feedback" name="manufacturer" as="div" errors={errors} message={"Please select a manufacturer"} />
                                    </div>
                                </div><br />
                                <h3>Changes</h3>
                                <div className={"row item bg-dark"} >
                                    <div className={"col-2 text-center"} >
                                        <br/><br />
                                        Suggestion<br />
                                        <FaArrowDown /><br />
                                        Fixture<br /><br />
                                        <b>Merge ?</b>
                                    </div>
                                    <div className={"col-2 text-center"} >
                                        Name<br /><br />
                                        <b>{suggestion?.name}</b><br />
                                        <FaArrowDown /><br />
                                        <b>{(selectedFixture)?selectedFixture?.name:"?"}</b><br /><br />
                                        <input className="form-check-input" type="checkbox" value="" id="name" {...register("mergeName")} defaultChecked={false}  />
                                    </div>
                                    <div className={"col-3 text-center"} >
                                        Number of channels<br /><br />
                                        <b>{suggestion?.numberOfChannels}</b><br />
                                        <FaArrowDown /><br />
                                        <b>{(selectedFixture)?selectedFixture?.numberOfChannels:"?"}</b><br /><br />
                                        <input className="form-check-input" type="checkbox" value="" id="channels_number" {...register("mergeChannelNumber")} defaultChecked={false}  />
                                    </div>
                                    <div className={"col-3 text-center"} >
                                        Manufacturer<br /><br />
                                        <b>{(foundedManufacturer)?foundedManufacturer?.name:"(Not found in DB)"}</b><br />
                                        <FaArrowDown /><br />
                                        <b>{(selectedFixture)?selectedFixture?.manufacturer.name:"?"}</b><br /><br />
                                        <input disabled={!foundedManufacturer} className="form-check-input" type="checkbox" value="" id="manufacturer_name" {...register("mergeManufacturerName")} defaultChecked={false}  />
                                    </div>
                                    <div className={"col-2 text-center"} >
                                        Type<br /><br />
                                        <b>{(foundedFixtureType)?foundedFixtureType?.label:"(Not found in DB)"}</b><br />
                                        <FaArrowDown /><br />
                                        <b>{(selectedFixture)?selectedFixture?.fixtureType.label:"?"}</b><br /><br />
                                        <input disabled={!foundedFixtureType} className="form-check-input" type="checkbox" value="" id="type" {...register("mergeType")} defaultChecked={false}  />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <input className={"btn btn-primary float-right"} type="submit" value={"Merge fixture"} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
          }
      <Modal
          isOpen={confirmModalIsOpen}
          onRequestClose={closeConfirmModal}
          style={customModalStyles}
          contentLabel="Confirm"
          className="Modal"
          overlayClassName="Overlay"
      >
          <h3>Confirm merge</h3><br />
          <div className={"close"}>
              <button className="btn" onClick={() => closeConfirmModal()}><FaTimes /></button>
          </div>
          <div className="row gy-3 row-cols-1 list">
              We merge these elements :<br />
              <ul>
                  <li>Properties</li>
                  <li hidden={!getValues("mergeName")} >Name</li>
                  <li hidden={!getValues("mergeChannelNumber")} >Number of channels</li>
                  <li hidden={!getValues("mergeManufacturerName")} >Manufacturer</li>
                  <li hidden={!getValues("mergeType")} >Type</li>
              </ul>
          </div>
          <div className="row gy-3 row-cols-1">
              <div className="btn-group">
                  <button className="btn btn-primary" onClick={() => merge()} >Yes</button>
                  <button className="btn btn-dark" onClick={() => closeConfirmModal()} >No</button>
              </div>
          </div>
      </Modal>
      </div>
  )
}
