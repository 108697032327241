import * as React from 'react'
import { NavLink } from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import { WindowInterface } from '../interfaces/Interface';

import moment from "moment-timezone";
import {MomentInput} from "moment/moment";
import {SuggestionModel} from "../interfaces/SuggestionModel";
import {FaAngleRight, FaShare} from "react-icons/fa";

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [suggestions, setSuggestions] = useState<SuggestionModel[]>([]);
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/suggestions').then(data => setSuggestions(data.data));
            setisLoading(false);
        }
        getList();
    }, []);

  return (
      <div>
          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1"} >
              <div className="col">
                <h1>Suggestions</h1>
              </div>
          </div>

          {isLoading &&
              <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
              </div>
          }

          {!isLoading && suggestions && suggestions.filter(value => value.deletedAt === null).length === 0 &&
              <div className={"no-item"}>
                  <FaShare/><br />
                  Awesome, no more suggestions
              </div>
          }

          {!isLoading &&
              <div className="row gy-3 row-cols-1 list">
                  {suggestions.filter(value => value.deletedAt == null).map((value, index) => {
                      return (
                          <div className="col" key={index}>
                              <NavLink to={"/suggestions/" + value.id} >
                                  <div
                                      className={`row shadow-sm item bg-dark`}>
                                      <div className="col-lg-5 col-md-6 col-sm-6 col-6 my-auto">
                                          <h4>{value.name}</h4>
                                          <span className={"text-muted"} >{value.nameOfManufacturer}</span>
                                      </div>
                                      <span className="col-lg-4 col-md-6 col-sm-6 col-6 my-auto">
                                          {(value.email) &&
                                            value.email
                                          }
                                          {(!value.email) &&
                                          <span className={"text-muted"} >No email provided</span>
                                          }<br/>
                                          <span className={"text-muted"} >{moment(value.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss") }</span>
                                      </span>
                                      <span className="col-lg-2 col-md-6 col-sm-6 col-6 my-auto">
                                          {value.type}<br/>
                                          {value.numberOfChannels} channels
                                      </span>
                                      <div className="col-lg-1 col-md-6 col-sm-6 col-6 my-auto">
                                          <FaAngleRight/>
                                      </div>
                                  </div>
                              </NavLink>
                          </div>
                      )
                  })}
              </div>
          }
      </div>
  )
}
