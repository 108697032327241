import * as React from 'react'
import {NavLink, Redirect, useParams} from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import { WindowInterface } from '../interfaces/Interface';

import { ManufacturerModel } from '../interfaces/ManufacturerModel';

import blinder from "../assets/types/blinder.png";
import fog from "../assets/types/fog.png";
import laser from "../assets/types/laser.png";
import led from "../assets/types/led.png";
import movinghead from "../assets/types/moving-head.png";
import par from "../assets/types/par.png";
import scanner from "../assets/types/scanner.png";
import strobe from "../assets/types/strobe.png";
import unknown from "../assets/types/unknown.png";

import {FaExclamationTriangle, FaAngleRight, FaPen, FaTimes} from 'react-icons/fa';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Modal from "react-modal";
import moment from "moment-timezone";
import {MomentInput} from "moment/moment";

const customStyles = {
    content : {
        width                 : '500px',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [redirect, setRedirect] = useState<string>("");
    const [manufacturer, setManufacturer] = useState<ManufacturerModel>();
    const [fixtures, setFixtures] = useState([{id: -1, name: "", reportCount: 0, numberOfChannels: 0, fixtureType: {label: "", image: ""}, manufacturer: {id: -1, name: ""}, deletedAt: null}]);
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    interface Params {
        manufacturerId: string
    }
    let { manufacturerId } = useParams<Params>();

    const getImage = (code: string): string => {
        if(code === "blinder")
            return blinder;
        else if (code === "fog")
            return fog;
        else if (code === "laser")
            return laser;
        else if (code === "led")
            return led;
        else if (code === "movinghead")
            return movinghead;
        else if (code === "par")
            return par;
        else if (code === "scanner")
            return scanner;
        else if (code === "strobe")
            return strobe;
        return unknown;
    };

    const { register, handleSubmit, reset} = useForm();
    const onSubmit = (data : any) => {
        if(manufacturer && manufacturer.name !== data.name){
            manufacturer.name = data.name;
            axiosInstance.current?.put('/v1/manufacturers/'+manufacturer.id, manufacturer).then(data => {
                if(data.status === 200) {
                    closeModal();
                    toast.success("Manufacturer updated");
                }else{
                    toast.error("An error as occurred");
                }
            });
            setManufacturer(manufacturer);
        }else{
            closeModal();
        }
    }

    const [editModalIsOpen,setEditModalIsOpen] = useState(false);
    function openModal() {
        reset();
        setEditModalIsOpen(true);
    }
    function closeModal(){
        setEditModalIsOpen(false);
    }

    const [deleteModalIsOpen,setDeleteModalIsOpen] = useState(false);
    function closeDeleteModalIsOpen(){
        setDeleteModalIsOpen(false);
    }
    function openDeleteModalIsOpen(){
        setDeleteModalIsOpen(true);
    }

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers/'+manufacturerId+'/fixtures').then(data => setFixtures(data.data));
            axiosInstance.current && await axiosInstance.current.get('/v1/manufacturers/'+manufacturerId).then(data => setManufacturer(data.data));
            setisLoading(false);
        }
        getList();
    }, []);

    function deleteManufacturer(manufacturer: ManufacturerModel | undefined){
        if(manufacturer !== undefined) {
            axiosInstance.current && axiosInstance.current.delete('/v1/manufacturers/' + manufacturer.id).then(data => {
                if (data.status === 200) {
                    setRedirect("/manufacturers/");
                    toast.success("Manufacturer deleted")
                } else {
                    toast.error("An error as occurred");
                }
            });
        }
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

  return (
      <div>
          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2"} >
              <div className="col">
                <h1>{manufacturer?.name} <button className="btn btn-primary rounded-circle" onClick={() => openModal()}><FaPen/></button></h1>
              </div>
          </div>

          {isLoading &&
              <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
              </div>
          }

          {!isLoading &&
              <div>
                  <div className="row gy-3 row-cols-1 list">
                      {fixtures.filter(value => value.deletedAt == null).map((value, index) => {
                          return (
                              <div className="col" key={index}>
                                  <NavLink to={"/fixtures/" + value.id} >
                                      <div
                                          className={`row shadow-sm item ${value.reportCount > 0 ? "bg-warning" : "bg-dark"}`}>
                                          <div className="col-lg-7 col-md-4 col-sm-4 col-5 my-auto">
                                              {value.reportCount > 0 &&
                                              <div className={"state"} title="Report(s) on this fixture">
                                                  <FaExclamationTriangle/>
                                              </div>
                                              }
                                              <h4>{value.name}</h4>
                                              <span className={"text-muted"} >{value.manufacturer.name}</span>
                                          </div>
                                          <div className="col-lg-1 col-md-2 col-sm-2 col-1 d-none d-sm-block my-auto">
                                              <img alt={value.fixtureType.label} src={getImage (value.fixtureType.image)} width={"50px"}/>
                                          </div>
                                          <span className="col-lg-3 col-md-4 col-sm-4 col-5 my-auto">
                                              {value.fixtureType.label}<br/>
                                              {value.numberOfChannels} channels
                                          </span>
                                          <div className="col-lg-1 col-md-2 col-sm-2 col-2 my-auto">
                                              <div className="dropdown">
                                                  <FaAngleRight/>
                                              </div>
                                          </div>
                                      </div>
                                  </NavLink>
                              </div>
                          )
                      })}
                  </div>
                  <br />
                  {manufacturer &&
                  <div className="row gy-3 row-cols-2">
                      <div className={"col"}>
                          <b>Created at</b> : {moment(manufacturer.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss")}<br />
                          <b>Updated at</b> : {moment(manufacturer.updatedAt as MomentInput).format("DD/MM/YYYY HH:mm:ss")}
                      </div>
                      <div className={"col"} >
                          <button className={"btn btn-danger float-right"} onClick={() => openDeleteModalIsOpen()} >Delete</button>
                      </div>
                  </div>
                  }
              </div>
          }
          <Modal
              isOpen={editModalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Edit manufacturer"
              className="Modal"
              overlayClassName="Overlay"
          >

              <h2>
                  Edit manufacturer
              </h2>
              <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" id="name" placeholder={"Name of manufacturer"} {...register("name", { required: true })} defaultValue={manufacturer?.name} required />
                  </div>
                  <input className={"btn btn-primary"} type="submit" />
              </form>
          </Modal>
          <Modal
              isOpen={deleteModalIsOpen}
              onRequestClose={closeDeleteModalIsOpen}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Confirm delete</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeDeleteModalIsOpen()}><FaTimes /></button>
              </div>
              {manufacturer && manufacturer?.fixtureCount > 0 &&
                  <div className={"alert alert-warning"}>
                      Manufacturer is containing fixtures so is not deletable.
                  </div>
              }
              <div className="row gy-3 row-cols-1">
                  <div className="btn-group">
                      <button className={(manufacturer && manufacturer?.fixtureCount > 0)?"btn btn-primary disabled":"btn btn-primary"} onClick={() => deleteManufacturer(manufacturer)} >Yes</button>
                      <button className={"btn btn-dark"} onClick={() => closeDeleteModalIsOpen()} >No</button>
                  </div>
              </div>
          </Modal>
      </div>
  )
}
