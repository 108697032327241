import {PropertiesProps, PropertyProps, RangeProps} from "../../interfaces/Interface";
import React, {useEffect, useState} from 'react'
import Range from "./Range";

import { FaWhmcs } from 'react-icons/fa';

const Property: React.FC<PropertiesProps> = (props: PropertiesProps) => {
    const [ranges, setRanges] = useState(new Array<RangeProps>());
    const [title, setTitle] = useState(new Array<RangeProps>());
    const [isCustom, setIsCustom] = useState(false);
    const [titles, setTitles] = useState<any>({
        "dimmerProperty": "Dimmer",
        "staticProperty": "Static",
        "shutterProperty": "Strobe",
        "ColorPropertyDM": "Gobos colors",
        "positionPropertyF": "Fine location",
        "positionPropertyNF": "Location",
        "colorPropertyRGB": "Color RGB",
        "colorPropertyW": "White color",
        "colorPropertyA": "Amber color",
        "colorPropertyUV": "UV color",
        "ColorPropertyCMY": "CMY color"
    });

    const getPropertyHumanName = function(propertyCode:string){
        if(Object.keys(titles).find(element => element === propertyCode)) {
            return titles[propertyCode];
        }else{
            return propertyCode;
        }
    }

    const getPropertyHumanRangeName = function(propertyCode:string, key:string){
        switch (propertyCode+"_"+key) {
            case "dimmerProperty_dimmer": return "Intensity";
            case "shutterProperty_pointClosed": return "Closed";
            case "shutterProperty_point": return "Open";
            case "shutterProperty_range": return "Range";
            case "staticProperty_staticProp": return "Constant";
            case "ColorPropertyDM_color": return "Gobo color";
            case "colorPropertyA_amber": return "Amber";
            case "colorPropertyW_white": return "White";
            case "colorPropertyUV_uv": return "UV";
            case "colorPropertyRGB_r": return "R";
            case "colorPropertyRGB_g": return "G";
            case "colorPropertyRGB_b": return "B";
            case "ColorPropertyCMY_c": return "C";
            case "ColorPropertyCMY_m": return "M";
            case "ColorPropertyCMY_y": return "Y";
            case "positionPropertyF_x": return "X";
            case "positionPropertyF_y": return "Y";
            case "positionPropertyF_xf": return "XF";
            case "positionPropertyF_yf": return "XY";
            case "positionPropertyNF_x": return "X";
            case "positionPropertyNF_y": return "Y";
            default : return key
        }
    }

    const getPropertyHumanRangeColor = function(propertyCode:string, key:string){
        switch (propertyCode+"_"+key) {
            case "colorPropertyRGB_r": return "#ff0000";
            case "colorPropertyRGB_g": return "#00ff00";
            case "colorPropertyRGB_b": return "#0000ff";
            case "colorPropertyW_white": return "#FFFFFF";
            case "colorPropertyA_amber": return "#f1b967";
            case "colorPropertyUV_uv": return "#4a22b6";
            case "ColorPropertyCMY_c": return "#00FFFF";
            case "ColorPropertyCMY_m": return "#FF00FF";
            case "ColorPropertyCMY_y": return "#FFFF00";
            default : return "#0092FF"
        }
    }

    useEffect(() => {
        const parseProperty = async () => {
            var joined : Array<RangeProps> = [];
            Object.keys(props.property).map((key) => {
                if(key === "colors"){
                    props.property[key].map((color: {color: any, range: any}, key:number) => {
                        joined = joined.concat({
                            name: getPropertyHumanRangeName(props.name, "color"),
                            color: "rgb("+color.color.color.r+", "+color.color.color.g+", "+color.color.color.b+")",
                            value: {
                                canal: color.range.range.canal,
                                min: color.range.range.min,
                                max: color.range.range.max
                            },
                            custom: false
                        });
                    });
                }else if(key === "customProperties") {
                    titles[props.name] = props.property.name;
                    setTitles(titles);

                    props.property[key].map((custom:any) => {
                        custom.customProperty.customs.map((customFunction:any) => {
                            const customName = Object.keys(customFunction)[0];
                            if(Object.keys(customFunction[customName]).find(element => element === "range")){
                                joined = joined.concat({
                                    name: customName,
                                    color: getPropertyHumanRangeColor(custom.customProperty.key, customName),
                                    value: {
                                        canal: customFunction[customName].range.canal,
                                        min: customFunction[customName].range.min,
                                        max: customFunction[customName].range.max
                                    },
                                    custom: true
                                });
                            }
                        });
                    });
                    setIsCustom(true);
                }else {
                    if(Object.keys(props.property[key as keyof PropertyProps]).find(element => element === "range")){
                        joined = joined.concat({
                            name: getPropertyHumanRangeName(props.name, key),
                            color: getPropertyHumanRangeColor(props.name, key),
                            value: {
                                canal: props.property[key as keyof PropertyProps].range.canal,
                                min: props.property[key as keyof PropertyProps].range.min,
                                max: props.property[key as keyof PropertyProps].range.max
                            },
                            custom: false
                        });
                    }
                }
            })
            setRanges(joined);
        }
        parseProperty();
    }, []);

    useEffect(() => {
        setTitle(getPropertyHumanName(props.name));
    }, [titles]);

    return (
        <div className={"property"}>
            <div className="col" >
                <div className="row shadow-sm bg-dark item">
                    <div className="col-12 my-auto">
                        <h4>
                            {title}
                            {isCustom &&
                            (
                                <div className={"isCustom"} ><FaWhmcs/></div>
                            )
                            }
                        </h4>

                    </div>
                    {ranges.map((valueRange, indexRange) => {
                        return (
                            <div className="col-12 my-auto" key={indexRange}>
                                <Range name={valueRange.name} color={valueRange.color} value={valueRange.value} custom={valueRange.custom} ></Range>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
     );
}
 
export default Property;