import * as React from 'react'
import { useDebounce } from 'use-debounce';
import { useAxios } from '../utils/hooks'
import { useState, useEffect, ChangeEvent } from "react";
import { WindowInterface } from '../interfaces/Interface';
import {FaCheck, FaCloudDownloadAlt, FaPencilAlt, FaTimes} from "react-icons/fa";
import {HiDotsHorizontal} from "react-icons/hi";
import Modal from "react-modal";
import { BatchModel } from "../interfaces/BatchModel";
import {FileModel} from "../interfaces/FileModel";
import moment from "moment-timezone";
import {MomentInput} from "moment/moment";
import { toast } from 'react-toastify';
import {useForm} from "react-hook-form";

var validate = require('uuid-validate');

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [batchs, setBatchs] = useState<Array<BatchModel>>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [selectingBatch,setSelectingBatch] = useState<BatchModel | undefined>(undefined);

    const { register, handleSubmit, reset} = useForm();
    const onSubmit = (data : any) => {
        if(axiosInstance.current && selectingBatch !== undefined && selectingBatch.devComment !== data.comment) {
            selectingBatch.devComment = data.comment;
            axiosInstance.current?.put('/v1/logs/' + selectingBatch.id, selectingBatch).then(data => {
                if(data.status === 200) {
                    toast.success("Comment updated")
                    closeCommentModal();
                }else{
                    toast.error("An error as occurred");
                }
            });
        }else{
            closeCommentModal();
        }
    }
    const [commentModalIsOpen,setCommentModalIsOpen] = useState(false);
    function openCommentModal(batch: BatchModel) {
        setCommentModalIsOpen(true);
        setSelectingBatch(batch);
        reset();
    }
    function closeCommentModal(){
        setCommentModalIsOpen(false);
        setSelectingBatch(undefined);
        reset();
    }

    const [filesModalIsOpen,setFilesModalIsOpen] = useState(false);
    function openModal(batch: BatchModel) {
        setFilesModalIsOpen(true);
        setSelectingBatch(batch);
    }
    function closeModal(){
        setFilesModalIsOpen(false);
        setSelectingBatch(undefined);
    }

    function downloadFile(batch: BatchModel, file: FileModel){
        axiosInstance.current && axiosInstance.current.get('/v1/logs/'+batch.id+'/download/'+file.id).then(data => {
            window.open(data.data, '_blank');
            toast.success("Download started")
        });
    }

    function downloadBatch(batch: BatchModel | undefined){
        if(batch !== undefined){
            axiosInstance.current && axiosInstance.current.get('/v1/logs/'+batch.id+'/download/').then(data => {
                window.open(data.data, '_blank');
                toast.success("Download started")
            });
        }
    }

    function deleteBatch(batch: BatchModel | undefined){
        if(batch !== undefined){
            axiosInstance.current && axiosInstance.current.delete('/v1/logs/'+batch.id).then(data => {
                if(data.status === 200) {
                    if(batchs !== undefined){
                        const newList : Array<BatchModel> = batchs.map((item) => {
                            if (item.id === batch.id) {
                                item.deletedAt = "test";
                            }

                            return item;
                        });

                        setBatchs(newList);
                    }
                    toast.success("Batch mark as processed")
                }else{
                    toast.error("An error as occurred");
                }
            });
        }
    }

    const [text, setText] = useState("");
    const [value] = useDebounce(text, 500);

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/logs').then(data => setBatchs(data.data));
            setisLoading(false);
        }
        getList();
    }, []);

    useEffect(() => {
        if(!isLoading){
            setisLoading(true);
            const getList = async () => {
                if(value === ""){
                    axiosInstance.current && await axiosInstance.current.get('/v1/logs').then(data => setBatchs(data.data));
                }else if(!validate(value)){
                    setBatchs([]);
                }
                else{
                    axiosInstance.current && await axiosInstance.current.get('/v1/logs/search/' + value).then(data => setBatchs(data.data));
                }
                setisLoading(false);
            }
            getList();
        }
    }, [value]);

    const handleChange = (event: ChangeEvent<{ value: string }>) => {
        setText(event.target.value);
    }
  return (
      <div className={"logs"}>
          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2"} >
              <div className="col">
                <h1>Error logs</h1>
              </div>
              <div className="col">
                <input type="text" name="name" className={"search"} placeholder={"Search (UUID) ..."} value={text} onChange={handleChange}/>
              </div>
          </div>

          {isLoading &&
              <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
              </div>
          }

          {!isLoading &&
              <div className="row gy-3 row-cols-1 list">
                  {batchs!.map((value, index) => {
                      return (
                          <div className="col" key={index}>
                              <div
                                  className={`row shadow-sm bg-dark item ${(value.deletedAt)?"disabled":""}`}>
                                  <div className="col-sm-6 col-12 my-auto">
                                      <div className={"state"} title="This batch of logs is processed by dev team">
                                        { (value.deletedAt) ? (<FaCheck />) : ""}
                                      </div>
                                      <h4>{value.id}</h4>
                                      <span className={"text-muted"} >{moment(value.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss") } - {value.logFilesCount} files</span>
                                  </div>
                                  <div className="col-sm-5 col-12 my-auto comment">
                                      { (value.devComment) ? value.devComment.slice(0, 10) : "No dev comment"}
                                      { (value.devComment && value.devComment.length > 10) ? " ...":""}
                                      <span>
                                          <button className={"edit_comment"} onClick={() => openCommentModal(value)}><FaPencilAlt/></button>
                                      </span>
                                  </div>
                                  <div className="col-sm-1 col-12 my-auto">
                                      <div className="dropdown">
                                          <button className="btn btn-secondary dropdown-toggle"
                                             id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                              <HiDotsHorizontal/>
                                          </button>

                                          <ul className="dropdown-menu dropdown-menu-lg-end dropdown-menu-dark"
                                              aria-labelledby="dropdownMenuLink">
                                              <li><button className="dropdown-item" onClick={() => openModal(value)}>View files</button></li>
                                              { (!value.deletedAt) ? (<li><button className="dropdown-item" onClick={() => deleteBatch(value)}>Mark as processed</button></li>) : "" }
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      )
                  })}
              </div>
          }
          <Modal
              isOpen={commentModalIsOpen}
              onRequestClose={closeCommentModal}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <div className={"close"}>
                  <button className="btn" onClick={() => closeCommentModal()}><FaTimes /></button>
              </div><br />
              <div className="row gy-3 row-cols-1">
                  <form onSubmit={handleSubmit(onSubmit)} >
                      <div className="mb-3">
                          <textarea style={{height: "200px"}} className="form-control" id="comment" placeholder={"Developer comment ..."} {...register("comment")} defaultValue={selectingBatch?.devComment} >
                          </textarea>
                      </div>
                      <input className={"btn btn-primary"} type="submit" />
                  </form>
              </div>
          </Modal>
          <Modal
              isOpen={filesModalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Files"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>{selectingBatch?.id}</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeModal()}><FaTimes /></button>
              </div>
              <div className="row gy-3 row-cols-1 list">
                  {selectingBatch?.logFiles.map((file, index) => {
                      return (
                          <div className="col" key={index}>
                              <div
                                  className={`row shadow-sm bg-dark item`}>
                                  <div className="col-12 col-md-11 my-auto">
                                      {file.filename.replace(selectingBatch?.id + "/", "")}
                                  </div>
                                  <div className="col-12 col-md-1 my-auto">
                                      <button className="btn btn-secondary" onClick={() => downloadFile(selectingBatch, file)}><FaCloudDownloadAlt /></button>
                                  </div>
                                  <div className="col-12 col-md-12 my-auto">
                                      <ul>
                                          {file.logTags.map((valueTag, indexTag) => {
                                              return (
                                                  <li key={indexTag} >{valueTag.uuid}</li>
                                              )
                                          })}
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      )
                  })}
              </div><br />
              <div className="text-center">
                  <button className="btn btn-primary" onClick={() => downloadBatch(selectingBatch)}><FaCloudDownloadAlt /> Download all files</button>
              </div>
          </Modal>
      </div>
  )
}
