import * as React from 'react'
import {
    Route,
    BrowserRouter
} from "react-router-dom";

import { useKeycloak } from '@react-keycloak/web'

import HomePage from '../pages/Home'
import LoginPage from '../pages/Login'
import FixturesPage from '../pages/Fixtures'
import ManufacturersPage from '../pages/Manufacturers'
import ManufacturerFixturesPage from '../pages/ManufacturerFixtures'
import LogsPage from '../pages/Logs'
import FixturePage from '../pages/Fixture'
import SuggestionsPage from '../pages/Suggestions'
import SuggestionPage from '../pages/Suggestion'
import RequestsPage from '../pages/Requests'
import ReportsPage from '../pages/Reports'
import SuggestionCreatePage from '../pages/SuggestionCreate'
import SuggestionMergePage from '../pages/SuggestionMerge'

import { PrivateRoute } from './utils'

import Menu from '../components/Menu/Menu'

export const AppRouter = () => {
    const { keycloak } = useKeycloak()
    const { initialized } = useKeycloak()

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
      <BrowserRouter>
          <div className={"container"}>
              <div className={"row"} >
                  {!!keycloak?.authenticated && (
                    <Menu />
                  )}
                  <div className={"main"} >
                    <PrivateRoute exact path="/" component={HomePage} />
                    <PrivateRoute exact path="/fixtures" component={FixturesPage} />
                    <PrivateRoute exact path="/fixtures/:fixtureId" component={FixturePage} />
                    <PrivateRoute exact path="/manufacturers" component={ManufacturersPage} />
                    <PrivateRoute exact path="/manufacturers/:manufacturerId/fixtures" component={ManufacturerFixturesPage} />
                    <PrivateRoute exact path="/logs" component={LogsPage} />
                    <PrivateRoute exact path="/suggestions" component={SuggestionsPage} />
                    <PrivateRoute exact path="/suggestions/:suggestionId" component={SuggestionPage} />
                    <PrivateRoute exact path="/suggestions/:suggestionId/create" component={SuggestionCreatePage} />
                    <PrivateRoute exact path="/suggestions/:suggestionId/merge" component={SuggestionMergePage} />
                    <PrivateRoute exact path="/requests" component={RequestsPage} />
                    <PrivateRoute exact path="/reports" component={ReportsPage} />
                    <Route path="/login" component={LoginPage} />
                  </div>
              </div>
          </div>
          <div className={"version"} >
              <div className={"container"}>
                v{process.env.REACT_APP_VERSION}
              </div>
          </div>
      </BrowserRouter>
  )
}
