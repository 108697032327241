import * as React from 'react'
import {Redirect, useParams} from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import { WindowInterface } from '../interfaces/Interface';
import { FixtureModel } from '../interfaces/FixtureModel';

import blinder from "../assets/types/blinder.png";
import fog from "../assets/types/fog.png";
import laser from "../assets/types/laser.png";
import led from "../assets/types/led.png";
import movinghead from "../assets/types/moving-head.png";
import par from "../assets/types/par.png";
import scanner from "../assets/types/scanner.png";
import strobe from "../assets/types/strobe.png";
import unknown from "../assets/types/unknown.png";
import Property from "../components/Properties/Property";

import {FaExternalLinkAlt, FaPen, FaTimes, FaFileAlt} from 'react-icons/fa';
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import {FixtureTypeModel} from "../interfaces/FixtureTypeModel";
import {ManufacturerModel} from "../interfaces/ManufacturerModel";
import moment from "moment-timezone";
import {MomentInput} from "moment/moment";

const customStyles = {
    content : {
        width                 : '500px',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const customSelectStyles = {
    option: (provided:any, state:any) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
    })
}

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [redirect, setRedirect] = useState<string>("");
    const [properties, setProperties] = useState([]);
    const [fixture, setFixture] = useState<FixtureModel>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    interface Params {
        fixtureId: string
    }
    let { fixtureId } = useParams<Params>();

    const {control, register, handleSubmit, reset, formState: { errors }} = useForm();
    const onSubmit = (data : any) => {
        if(fixture && (fixture.name !== data.name ||
                        fixture.numberOfChannels !== data.numberOfChannels ||
                        fixture.manualUrl !== data.manualUrl ||
                        fixture.fixtureType.id !== data.fixtureType.value ||
                        fixture.manufacturer.id !== data.manufacturer.value)){
            axiosInstance.current && axiosInstance.current.get('/v1/fixturetypes/' + data.fixtureType.value).then(dataFixture => {
                axiosInstance.current && axiosInstance.current.get('/v1/manufacturers/' + data.manufacturer.value).then(dataManufacturer => {
                    fixture.name = data.name;
                    fixture.numberOfChannels = data.numberOfChannels;
                    fixture.manualUrl = data.manualUrl;
                    fixture.fixtureType = dataFixture.data;
                    fixture.manufacturer = dataManufacturer.data;
                    axiosInstance.current?.put('/v1/fixtures/' + fixture.id, fixture).then(data => {
                        if (data.status === 200) {
                            closeModal();
                            toast.success("Fixture updated");
                        } else {
                            toast.error("An error as occurred");
                        }
                    });
                    setFixture(fixture);
                });
            });
        }else{
            closeModal();
        }
    }

    const [editModalIsOpen,setEditModalIsOpen] = useState(false);
    function openModal() {
        reset();
        setEditModalIsOpen(true);
    }
    function closeModal(){
        setEditModalIsOpen(false);
    }

    useEffect(() => {
        const getList = async () => {
            if(fixtureId !== undefined){
                axiosInstance.current && await axiosInstance.current.get('/v1/fixtures/' + fixtureId).then(data => {
                    setFixture(data.data)
                    var json = eval("["+data.data.properties+"]")[0].properties;
                    setProperties(json);
                    console.log(json);
                });
                setisLoading(false);
            }
        }
        getList();
    }, []);

    const getImage = (code: string): string => {
        if(code === "blinder")
            return blinder;
        else if (code === "fog")
            return fog;
        else if (code === "laser")
            return laser;
        else if (code === "led")
            return led;
        else if (code === "movinghead")
            return movinghead;
        else if (code === "par")
            return par;
        else if (code === "scanner")
            return scanner;
        else if (code === "strobe")
            return strobe;
        return unknown;
    };

    const getManufacturersOptions = (input:string) => {
        return new Promise<{value:string,label:string}[]>((resolve) => {
            axiosInstance.current && axiosInstance.current.get((input !== "")?'/v1/manufacturers/search/'+input:"/v1/manufacturers/")
                .then(function (response) {
                    resolve(response.data.map((manufacturer: ManufacturerModel) => ({
                        value: manufacturer.id,
                        label: manufacturer.name
                    })));
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    const getFixtureTypesOptions = (input:string) => {
        return new Promise<{value:string,label:string}[]>((resolve) => {
            axiosInstance.current && axiosInstance.current.get((input !== "")?'/v1/fixturetypes/search/'+input:"/v1/fixturetypes/")
                .then(function (response) {
                    resolve(response.data.map((fixtureType: FixtureTypeModel) => ({
                        value: fixtureType.id,
                        label: fixtureType.label
                    })));
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function deleteFixture(fixture: FixtureModel | undefined){
        if(fixture !== undefined) {
            axiosInstance.current && axiosInstance.current.delete('/v1/fixtures/' + fixture.id).then(data => {
                if (data.status === 200) {
                    setRedirect("/fixtures/");
                    toast.success("Fixture deleted");
                } else {
                    toast.error("An error as occurred");
                }
            });
        }
    }

    const [deleteModalIsOpen,setDeleteModalIsOpen] = useState(false);
    function closeDeleteModalIsOpen(){
        setDeleteModalIsOpen(false);
    }
    function openDeleteModalIsOpen(){
        setDeleteModalIsOpen(true);
    }

    const [jsonModalIsOpen,setJsonModalIsOpen] = useState(false);
    function openJsonModal() {
        setJsonModalIsOpen(true);
    }
    function closeJsonModal(){
        setJsonModalIsOpen(false);
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

  return (
      <div className={"fixture"}>
          {isLoading &&
          <div className="spinner-border loader" role="status">
              <span className="sr-only"></span>
          </div>
          }

          {!isLoading &&
              <div className={"row"} >
                  <div className="col-12 col-md-12 col-lg-8">
                      <h1>{ fixture?.name }  <button className="btn btn-primary rounded-circle" onClick={() => openModal()}><FaPen/></button> <button className="btn btn-primary rounded-circle" onClick={() => openJsonModal()}><FaFileAlt/></button></h1>
                      <h3 className={"manufacturer"} >{fixture?.manufacturer.name}</h3>
                  </div>
                  <div className={"col-12 col-md-12 col-lg-4 my-auto"} >
                      <div className={"leftManufacturer"} >
                        <img alt={fixture?.fixtureType.label} src={getImage((fixture)?fixture.fixtureType.image:"")} width={"50px"}/>
                      </div>
                      <div className={"rightManufacturer"} >
                          {fixture?.fixtureType.label}<br/>
                          {fixture?.numberOfChannels} channels
                      </div>
                  </div>
              </div>
          }

          {!isLoading &&
              <div>
                  <div className="row gy-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 list" >
                      {properties.map((valueProperty, indexProperty) => {
                          return (
                              <Property key={indexProperty} name={Object.keys(valueProperty)[0]} property={valueProperty[Object.keys(valueProperty)[0]]} ></Property>
                          )
                      })}

                      {fixture?.manualUrl &&
                      <div>
                          <FaExternalLinkAlt/> <a href={fixture.manualUrl} target={"_blank"} rel={"noopener noreferrer"} >Open manual</a>
                      </div>
                      }
                  </div><br />
                  {fixture &&
                      <div className="row gy-3 row-cols-2">
                          <div className={"col"}>
                              <b>Created at</b> : {moment(fixture.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss")}<br />
                              <b>Updated at</b> : {moment(fixture.updatedAt as MomentInput).format("DD/MM/YYYY HH:mm:ss")}
                          </div>
                          <div className={"col"} >
                              <button className={"btn btn-danger float-right"} onClick={() => openDeleteModalIsOpen()} >Delete</button>
                          </div>
                      </div>
                  }
              </div>
          }
          <Modal
              isOpen={editModalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Edit fixture"
              className="Modal"
              overlayClassName="Overlay"
          >

              <h2>
                  Edit fixture
              </h2>
              <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" id="name" placeholder={"Name of fixture"} {...register("name", { required: true })} defaultValue={fixture?.name} required />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="channels" className="form-label">Number of channels</label>
                      <input type="text" className="form-control" id="channels" placeholder={"Number of channels"} {...register("numberOfChannels", { required: true })} defaultValue={fixture?.numberOfChannels} required />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="fixtureType" className="form-label">Fixture type</label>
                      <Controller
                          control={control}
                          name="fixtureType"
                          rules={{ required: true }}
                          defaultValue={{"value": fixture?.fixtureType.id, "label": fixture?.fixtureType.label}}
                          render={({
                                       field: { onChange, onBlur, value, name, ref },
                                       fieldState: { invalid, isTouched, isDirty, error },
                                   }) => (
                              <AsyncSelect value={value}
                                           onChange={onChange}
                                           inputRef={ref}
                                           id="fixtureType"
                                           name="fixtureType"
                                           styles={customSelectStyles}
                                           isClearable defaultOptions loadOptions={getFixtureTypesOptions}
                                           className={`${
                                               errors.fixtureType ? "is-invalid" : ""
                                           }`}
                              />
                          )}
                      />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="fixtureType" className="form-label">Manufacturer</label>
                      <Controller
                          control={control}
                          name="manufacturer"
                          rules={{ required: true }}
                          defaultValue={{"value": fixture?.manufacturer.id, "label": fixture?.manufacturer.name}}
                          render={({
                                       field: { onChange, onBlur, value, name, ref },
                                       fieldState: { invalid, isTouched, isDirty, error },
                                   }) => (
                              <AsyncSelect value={value}
                                           onChange={onChange}
                                           inputRef={ref}
                                           id="manufacturer"
                                           name="manufacturer"
                                           styles={customSelectStyles}
                                           isClearable defaultOptions loadOptions={getManufacturersOptions}
                                           className={`${
                                               errors.manufacturer ? "is-invalid" : ""
                                           }`}
                              />
                          )}
                      />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="manualUrl" className="form-label">Manual link</label>
                      <input type="text" className="form-control" id="manualUrl" placeholder={"Manual link"} {...register("manualUrl")} defaultValue={fixture?.manualUrl} />
                  </div>
                  <input className={"btn btn-primary"} type="submit" />
              </form>
          </Modal>
          <Modal
              isOpen={deleteModalIsOpen}
              onRequestClose={closeDeleteModalIsOpen}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Confirm delete</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeDeleteModalIsOpen()}><FaTimes /></button>
              </div><br />
              <div className="row gy-3 row-cols-1">
                  <div className="btn-group">
                      <button className="btn btn-primary" onClick={() => deleteFixture(fixture)} >Yes</button>
                      <button className="btn btn-dark" onClick={() => closeDeleteModalIsOpen()} >No</button>
                  </div>
              </div>
          </Modal>
          <Modal
              isOpen={jsonModalIsOpen}
              onRequestClose={closeJsonModal}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Json properties</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeJsonModal()}><FaTimes /></button>
              </div>
              {fixture?.properties}
          </Modal>
      </div>
  )
}
