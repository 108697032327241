import Keycloak from 'keycloak-js';
import { WindowInterface } from './interfaces/Interface';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const env = (window as WindowInterface & typeof globalThis).env;
const keycloak = Keycloak({
  url: env.REACT_APP_SSO_URL,
  realm: 'photon',
  clientId: 'photonadmin',
});

export default keycloak;
