import { MenuProps, WindowInterface } from "../../interfaces/Interface";
import { NavLink } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'
import { useState, useEffect} from "react";
import { useAxios } from '../../utils/hooks'
import React from 'react'
import logo from "../../assets/logo.png";

import "./styles.scss";

import { FaHome, FaRegLightbulb, FaPlus, FaShare, FaSignOutAlt, FaUserCircle, FaExclamationTriangle, FaTrademark, FaRegFileAlt } from 'react-icons/fa';

const Menu: React.FC<MenuProps> = () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation
    const [infos, setInfos] = useState({name: '', given_name: ''});
    const [stats, setStats] = useState({nbSuggestion: 0, nbRequest: 0, nbReport: 0, nbSyncToday: 0});

    const { keycloak } = useKeycloak()

    useEffect(() => {
        keycloak.loadUserInfo().then((userInfo:any) => {
            setInfos(userInfo)
        });

        const getStats = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/stats').then((data:any) => setStats(data.data));
        }
        getStats();
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true);
    }

    return (
        <div className={"Menu"}>
            <div className="Menu_menu" onClick={handleClick}>
                <div className={`top-bar ${isOpen ? "open" : ""}`}></div>
                <div className={`middle-bar ${isOpen ? "open" : ""}`}></div>
                <div className={`bottom-bar ${isOpen ? "open" : ""}`}></div>
            </div>
            <nav className={`col-2 sidebar ${isOpen ? "open" : ""}`}>
                <div className="sidebar-sticky">
                    <div className={"sidebar-logo"} >
                        <img src={logo} alt=""/>
                        Photon
                    </div>

                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact={true} className="nav-link" to={"/"} onClick={() => setIsOpen(false)} >
                                <FaHome />
                                Dashboard
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/fixtures"} onClick={() => setIsOpen(false)}>
                                <FaRegLightbulb />
                                Fixtures
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/manufacturers"} onClick={() => setIsOpen(false)}>
                                <FaTrademark />
                                Manufacturers
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/reports"} onClick={() => setIsOpen(false)}>
                                <FaExclamationTriangle />
                                Reports
                                { stats.nbReport ? <span className="bullet"></span> : null }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/requests"} onClick={() => setIsOpen(false)}>
                                <FaPlus />
                                Requests
                                { stats.nbRequest ? <span className="bullet"></span> : null }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/suggestions"} onClick={() => setIsOpen(false)}>
                                <FaShare />
                                Suggestions
                                { stats.nbSuggestion ? <span className="bullet"></span> : null }
                            </NavLink>
                        </li>
                    </ul>

                    <h5 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                        <span>App iOs</span>
                    </h5>

                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/logs"} onClick={() => setIsOpen(false)}>
                                <FaRegFileAlt />
                                Error logs
                            </NavLink>
                        </li>
                    </ul>

                    <div className={"account"} >
                        <h5 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                            <span>{infos.name}</span>
                        </h5>

                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <a className="nav-link" href={ keycloak.createAccountUrl() }>
                                    <FaUserCircle />
                                    Gérer mon compte
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ keycloak.createLogoutUrl() }>
                                    <FaSignOutAlt />
                                    Déconnexion
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
     );
}
 
export default Menu;