import * as React from 'react'
import { useAxios } from '../utils/hooks'
import { useState, useEffect, useRef } from "react";
import moment from 'moment-timezone';
import { WindowInterface } from '../interfaces/Interface';

import 'chart.js/auto';
import type { ChartData } from 'chart.js';
import { Chart } from 'react-chartjs-2';

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [chartData, setChartData] = useState<ChartData<'bar'>>({
        datasets: [],
      });
    const [isLoading, setisLoading] = useState(true);

    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    useEffect(() => {
        const getStats = async () => {
            var end = moment.tz("Europe/Paris").startOf('day').utc();
            var start = moment.tz("Europe/Paris").startOf('day').subtract(1, 'month');
            await axiosInstance.current!.get('/v1/stats/nbSyncPerDay?start='+start.toISOString()+'&end='+end.toISOString()).then(data => {
                let syncStatsX = [];
                let syncStatsYAll = [];
                let syncStatsYV1 = [];
                let syncStatsYV2 = [];
                let syncStatsYPremiumStarter = [];
                let syncStatsYPremiumPro = [];
                let syncStatsYNotPremium = [];
                for (let key in data.data.all) {
                    const date = moment.tz(data.data.all[key].bucket_start, "Europe/Paris");
                    syncStatsX.push(date.utc(true).format("DD/MM/YYYY"));
                    syncStatsYAll.push(data.data.all[key].nb);
                }
                for (let key in data.data.v1) {
                    syncStatsYV1.push(data.data.v1[key].nb);
                }
                for (let key in data.data.v2) {
                    syncStatsYV2.push(data.data.v2[key].nb);
                }
                for (let key in data.data.all) {
                    syncStatsYPremiumStarter.push(data.data.premium_starter[key].nb);
                    syncStatsYPremiumPro.push(data.data.premium_pro[key].nb);
                    syncStatsYNotPremium.push(data.data.not_premium[key].nb);
                }

                const dataChart = {
                    labels: syncStatsX,
                    datasets: [
                        {
                            label: 'v1',
                            data: syncStatsYV1,
                            fill: false,
                            backgroundColor: 'rgb(13, 110, 253)',
                            borderColor: 'rgba(13, 110, 253, 0.2)',
                            borderRadius: 5,
                            stack: 'Stack 0',
                        },
                        {
                            label: 'v2',
                            data: syncStatsYV2,
                            fill: false,
                            backgroundColor: 'RGB(255, 69, 164)',
                            borderColor: 'rgba(255, 69, 164, 0.2)',
                            borderRadius: 5,
                            stack: 'Stack 0',
                        },
                        {
                            label: 'Starter',
                            data: syncStatsYPremiumStarter,
                            fill: false,
                            backgroundColor: 'rgba(0, 255, 255, 1)',
                            borderColor: 'rgba(0, 255, 255, 0.2)',
                            borderRadius: 5,
                            stack: 'Stack 1',
                        },
                        {
                            label: 'Pro',
                            data: syncStatsYPremiumPro,
                            fill: false,
                            backgroundColor: 'rgba(127, 0, 255, 1)',
                            borderColor: 'rgba(127, 0, 255, 0.2)',
                            borderRadius: 5,
                            stack: 'Stack 1',
                        }
                    ],
                  };

                setChartData({
                    ...dataChart,
                    datasets: dataChart.datasets.map(dataset => ({
                        ...dataset
                    })),
                });
            });
            setisLoading(false);
        }
        getStats();
    }, []);

    if(isLoading)
        return (
            <div className="spinner-border loader" role="status">
                <span className="sr-only"></span>
            </div>
        );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
        plugins: {
            tooltip: {
                padding: 10,
                bodyFont: {
                    size: 14,
                    weight: "300",
                    family: "Segoe UI"
                },
                titleFont: {
                    size: 14,
                    weight: "500",
                    family: "Segoe UI"
                },
                backgroundColor: "#1a1a1b",
                borderColor: "#0a58ca",
                borderWidth: 2,
            },
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 14,
                        weight: "400",
                        family: "Segoe UI"
                    },
                    boxHeight: 20,
                    padding: 20,
                }
            }
        }
    };

    return (
      <div>
          <h1>Dashboard</h1>
          <div className="wrapper">
              <Chart type='bar' data={chartData} options={options} />
          </div>
      </div>
  )
}
