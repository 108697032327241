import * as React from 'react'
import { useCallback } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'
import logo from "../assets/logo.png";

const LoginPage = () => {

  const location = useLocation<{ [key: string]: unknown }>()
  
  const currentLocationState = location.state || {
    from: { pathname: '/' },
  }

  const { keycloak } = useKeycloak()
  
  const login = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  console.log(currentLocationState?.from)

  if (keycloak?.authenticated)
    return <Redirect to={currentLocationState?.from as string} />

  return (
    <main className="login">
      <img src={logo} alt=""/>
      <h1>Login</h1>
      <p className="lead">Connect with your photon-ios admin account.</p>
      <p className="lead">
        <button type="button" onClick={login}>
          Redirect to SSO
        </button>
      </p>
    </main>
  )
}

export default LoginPage
