import {RangeProps} from "../../interfaces/Interface";
import React from 'react'

const Range: React.FC<RangeProps> = (range: RangeProps) => {
    const divStyle = {
        backgroundColor: range.color,
    };

    return (
        <div className="row range">
            <span className="legend col-3 my-auto" style={divStyle}>{range.name}</span>
            <span className="number col-3 my-auto">Channel number <span>{ range.value.canal }</span></span>
            <span className="col-5" ></span>
            <span className="rangeNumbers col-1 my-auto" >{ range.value.min }<br />{ range.value.max }</span>
        </div>
    );
}

export default Range;