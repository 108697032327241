import * as React from 'react'
import {Redirect, useParams, NavLink} from "react-router-dom";
import { useAxios } from '../utils/hooks'
import { useState, useEffect} from "react";
import {WindowInterface} from '../interfaces/Interface';

import Property from "../components/Properties/Property";
import {SuggestionModel} from "../interfaces/SuggestionModel";
import moment from "moment-timezone";
import {MomentInput} from "moment/moment";
import Modal from "react-modal";
import {FaFileAlt, FaTimes} from "react-icons/fa";
import {toast} from "react-toastify";

const customStyles = {
    content : {
        width                 : '500px',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [redirect, setRedirect] = useState<string>("");
    const [properties, setProperties] = useState([]);
    const [suggestion, setSuggestion] = useState<SuggestionModel>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    interface Params {
        suggestionId: string
    }
    let { suggestionId } = useParams<Params>();

    useEffect(() => {
        const getList = async () => {
            if(suggestionId !== undefined){
                axiosInstance.current && await axiosInstance.current.get('/v1/suggestions/' + suggestionId).then(data => {
                    setSuggestion(data.data)
                    var json = eval("["+data.data.properties+"]")[0].properties;
                    setProperties(json);
                    console.log(json);
                });
                setisLoading(false);
            }
        }
        getList();
    }, []);

    const [rejectModalIsOpen,setRejectModalIsOpen] = useState(false);
    function closeRejectModalIsOpen(){
        setRejectModalIsOpen(false);
    }
    function openRejectModalIsOpen(){
        setRejectModalIsOpen(true);
    }
    function deleteSuggestion(){
        if(suggestion !== undefined) {
            axiosInstance.current && axiosInstance.current.delete('/v1/suggestions/' + suggestion.id).then(data => {
                if (data.status === 200) {
                    toast.success("Suggestion deleted");
                    closeRejectModalIsOpen();
                    setRedirect("/suggestions")
                } else {
                    toast.error("An error as occurred");
                }
            });
        }
    }

    const [jsonModalIsOpen,setJsonModalIsOpen] = useState(false);
    function openJsonModal() {
        setJsonModalIsOpen(true);
    }
    function closeJsonModal(){
        setJsonModalIsOpen(false);
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

  return (
      <div className={"fixture"}>
          {isLoading &&
          <div className="spinner-border loader" role="status">
              <span className="sr-only"></span>
          </div>
          }

          {!isLoading &&
            <div>
              <div className={"row"} >
                  <div className="col-12 col-md-12 col-lg-10">
                      <h1>{ suggestion?.name } <button className="btn btn-primary rounded-circle" onClick={() => openJsonModal()}><FaFileAlt/></button></h1>
                      <h3 className={"manufacturer"} >{suggestion?.nameOfManufacturer}</h3>
                  </div>
                  <div className={"col-12 col-md-12 col-lg-2 my-auto"} >
                      {suggestion?.type}<br/>
                      {suggestion?.numberOfChannels} channels
                  </div>
              </div>
              <div className="row gy-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 list" >
                  {properties.map((valueProperty, indexProperty) => {
                      return (
                          <Property key={indexProperty} name={Object.keys(valueProperty)[0]} property={valueProperty[Object.keys(valueProperty)[0]]} ></Property>
                      )
                  })}
              </div><br />
                { suggestion &&
                    <div className="row gy-3 row-cols-2">
                        <div className={"col"}>
                            <b>Created at</b> : {moment(suggestion.createdAt as MomentInput).format("DD/MM/YYYY HH:mm:ss")}<br />
                            <b>Updated at</b> : {moment(suggestion.updatedAt as MomentInput).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                        <div className={"col"} >
                            <button className={"btn btn-danger float-right"} onClick={() => openRejectModalIsOpen()} >Delete suggestion</button>
                            <NavLink style={{"marginRight": "10px"}} className={"btn btn-primary float-right"} to={"/suggestions/"+suggestion.id+"/create"} >Create fixture</NavLink>
                            <NavLink style={{"marginRight": "10px"}} className={"btn btn-light float-right"} to={"/suggestions/"+suggestion.id+"/merge"} >Merge in fixture</NavLink>
                        </div>
                    </div>
                }
            </div>
          }
          <Modal
              isOpen={rejectModalIsOpen}
              onRequestClose={closeRejectModalIsOpen}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Confirm delete</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeRejectModalIsOpen()}><FaTimes /></button>
              </div><br />
              <div className="row gy-3 row-cols-1">
                  <div className="btn-group">
                      <button className="btn btn-primary" onClick={() => deleteSuggestion()} >Yes</button>
                      <button className="btn btn-dark" onClick={() => closeRejectModalIsOpen()} >No</button>
                  </div>
              </div>
          </Modal>
          <Modal
              isOpen={jsonModalIsOpen}
              onRequestClose={closeJsonModal}
              style={customStyles}
              contentLabel="Comment"
              className="Modal"
              overlayClassName="Overlay"
          >
              <h3>Json properties</h3><br />
              <div className={"close"}>
                  <button className="btn" onClick={() => closeJsonModal()}><FaTimes /></button>
              </div>
              {suggestion?.properties}
          </Modal>
      </div>
  )
}
