import * as React from 'react'
import { NavLink } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useAxios } from '../utils/hooks'
import { useState, useEffect, ChangeEvent } from "react";
import { WindowInterface } from '../interfaces/Interface';
import {FixtureModel } from '../interfaces/FixtureModel';

import blinder from "../assets/types/blinder.png";
import fog from "../assets/types/fog.png";
import laser from "../assets/types/laser.png";
import led from "../assets/types/led.png";
import movinghead from "../assets/types/moving-head.png";
import par from "../assets/types/par.png";
import scanner from "../assets/types/scanner.png";
import strobe from "../assets/types/strobe.png";
import unknown from "../assets/types/unknown.png";

import { FaExclamationTriangle, FaAngleRight, FaFileAlt } from 'react-icons/fa';

export default () => {
    const env = (window as WindowInterface & typeof globalThis).env;
    const [fixtures, setFixtures] = useState<FixtureModel[]>();
    const [isLoading, setisLoading] = useState(true);
    const axiosInstance = useAxios(env.REACT_APP_API_URL ? env.REACT_APP_API_URL! : 'https://apiv2.photon-ios.com') // see https://github.com/panz3r/jwt-checker-server for a quick implementation

    const [text, setText] = useState("");
    const [value] = useDebounce(text, 500);

    const getImage = (code: string): string => {
        if(code === "blinder")
            return blinder;
        else if (code === "fog")
            return fog;
        else if (code === "laser")
            return laser;
        else if (code === "led")
            return led;
        else if (code === "movinghead")
            return movinghead;
        else if (code === "par")
            return par;
        else if (code === "scanner")
            return scanner;
        else if (code === "strobe")
            return strobe;
        return unknown;
    };

    useEffect(() => {
        const getList = async () => {
            axiosInstance.current && await axiosInstance.current.get('/v1/fixtures').then(data => setFixtures(data.data));
            setisLoading(false);
        }
        getList();
    }, []);
    //eslint-disable-next-line

    useEffect(() => {
        if(!isLoading){
            setisLoading(true);
            const getList = async () => {
                axiosInstance.current && await axiosInstance.current?.post('/v1/fixtures/search/', {query: value}).then(data => setFixtures(data.data));
                setisLoading(false);
            }
            getList();
        }
    }, [value]);
    //eslint-disable-next-line

    const handleChange = (event: ChangeEvent<{ value: string }>) => {
        setText(event.target.value);
    }

  return (
      <div>
          <div className={"row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2"} >
              <div className="col">
                <h1>Fixtures</h1>
              </div>
              <div className="col">
                <input type="text" name="name" className={"search"} placeholder={"Search ..."} value={text} onChange={handleChange}/>
              </div>
          </div>

          {isLoading &&
              <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
              </div>
          }

          {!isLoading && fixtures &&
              <div className="row gy-3 row-cols-1 list">
                  {fixtures.filter(value => value.deletedAt == null).sort((a, b) => {return (a.manufacturer.name < b.manufacturer.name)?-1:1}).map((value, index) => {
                      return (
                          <div className="col" key={index}>
                              <NavLink to={"/fixtures/" + value.id} >
                                  <div
                                      className={`row shadow-sm item ${value.reportCount > 0 ? "bg-warning" : "bg-dark"}`}>
                                      <div className="col-lg-7 col-md-4 col-sm-4 col-5 my-auto">
                                          {value.reportCount > 0 &&
                                          <div className={"state"} title="Report(s) on this fixture">
                                              <FaExclamationTriangle/>
                                          </div>
                                          }
                                          {value.manualUrl &&
                                          <div className={"state"} title="Manual on this fixture">
                                              <FaFileAlt/>
                                          </div>
                                          }
                                          <h4>{value.name}</h4>
                                          <span className={"text-muted"} >{value.manufacturer.name}</span>
                                      </div>
                                      <div className="col-lg-1 col-md-2 col-sm-2 col-1 d-none d-sm-block my-auto">
                                          <img alt={value.fixtureType.label} src={getImage (value.fixtureType.image)} width={"50px"}/>
                                      </div>
                                      <span className="col-lg-3 col-md-4 col-sm-4 col-5 my-auto">
                                          {value.fixtureType.label}<br/>
                                          {value.numberOfChannels} channels
                                      </span>
                                      <div className="col-lg-1 col-md-2 col-sm-2 col-2 my-auto">
                                          <FaAngleRight/>
                                      </div>
                                  </div>
                              </NavLink>
                          </div>
                      )
                  })}
              </div>
          }
      </div>
  )
}
